import { FooterElement, LogoPartners } from './index';

import LogoLeReacteur from 'src/components/Logo/LogoLeReacteurFooter';
import Obfuscate from 'react-obfuscate';
import { Paragraph } from '../Typography';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import certificationQualiopi from '../../data/document/Certificat_F0411.pdf';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';

const date = new Date();

const FooterLarge = ({ pageName }) => {
  return (
    <div>
      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          [mediaQueries.tabletLandscapeUp]: {
            flexDirection: 'row',
          },
        }}
      >
        <div
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            [mediaQueries.tabletLandscapeUp]: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              marginRight: 20,
            },
          }}
        >
          <div>
            <LogoLeReacteur css={{ width: 75 }} />
            <ul
              css={{
                listStyle: 'none',
                fontSize: 16,
                lineHeight: '24px',
                marginTop: 15,
                letterSpacing: 0.4,
              }}
            >
              <li css={{ fontWeight: 'bold', fontSize: 19 }}>Le Reacteur</li>
              <li>55, Rue Etienne Marey</li>
              <li>75020 - Paris, France</li>
              <li>
                <Obfuscate
                  css={{ marginTop: 10, textDecoration: 'none', color: colors.white }}
                  email="contact@lereacteur.io"
                  element="span"
                />
              </li>
              <li>
                <a
                  css={{ textDecoration: 'none', color: colors.white }}
                  href="tel:+33179738728"
                  aria-label="numéro de téléphone"
                >
                  +33 (0)1.79.738.728
                </a>
              </li>
              <li css={{ marginTop: 30 }}>Déclaration d'activité n°11755531275 </li>
              <li>Référencement DataDock n°0029509</li>
            </ul>
          </div>

          {pageName === 'Homepage' ? (
            <div
              css={{
                [mediaQueries.tabletLandscapeUp]: {
                  marginTop: 35,
                },
              }}
            >
              <a href={certificationQualiopi} download>
                <StaticImage
                  src="../../data/images/institutionals/LogoQualiopi.png"
                  alt="logo Qualiopi"
                  aria-label="certificat Qualiopi"
                  // placeholder="none"
                  css={{
                    width: 215,
                    borderRadius: 5,
                  }}
                />
              </a>
            </div>
          ) : null}
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            flex: 2,
            justifyContent: 'space-between',
          }}
        >
          <div>
            <FooterElement
              heading="Formations"
              item={[
                {
                  text: 'Développeur Blockchain - Temps Partiel',
                  url: '/bootcamp-web3/formation-developpeur-blockchain/',
                  isHidden: true,
                },
                {
                  text: 'Développeur Web et Web Mobile - E-Learning',
                  url: '/cours-informatique/formation-developpeur-web/',
                },
                {
                  text: 'Développeur Web et Mobile - Temps Plein',
                  url: '/bootcamp-javascript/formation-developpeur-web-mobile/',
                },
                {
                  text: 'Développeur Web et Mobile - Temps Partiel',
                  url: '/bootcamp-javascript/formation-developpeur-web-mobile-temps-partiel/',
                },
                {
                  text: 'Developpeur Web et Mobile - Alternance',
                  url: '/formation-alternance/concepteur-developpeur-applications/',
                },
                {
                  text: 'Préparation HTML/CSS/JS - Formation gratuite',
                  url: '/apprendre-a-coder-gratuitement/',
                },
              ]}
            />
            <FooterElement
              heading="Entreprises"
              item={[
                { text: 'Formation React + Redux', url: '/formation/react-redux/' },
                { text: 'Formation React Native', url: '/formation/react-native/' },
                { text: 'Formation React Avancé', url: '/formation/react-avance/' },
                { text: 'Formation TypeScript', url: '/formation/typescript/' },
                { text: 'Formation Node.js', url: '/formation/node-js/' },
              ]}
              css={{ marginTop: 35 }}
            />
            <FooterElement
              heading="Certifications"
              item={[
                {
                  text: `Concepteur Développeur d'Applications - RNCP6`,
                  url: '/titre-rncp-concepteur-developpeur-d-applications',
                },
                {
                  text: 'Développeur Web et Web Mobile - RNCP5',
                  url: '/titre-rncp-developpeur-web-et-web-mobile',
                },
                {
                  text: 'Développeur Web - Webecom - RNCP5',
                  url: '/titre-rncp-developpeur-web',
                },
                {
                  text: 'Exploiter la Blockchain',
                  url: '/certification-professionnelle-exploiter-la-blockchain-dans-le-developpement-d-applications',
                  isHidden: true,
                },
                {
                  text: 'Concepteur - Développeur d’applications Full Stack',
                  url: '/cours-informatique/formation-developpeur-full-stack/',
                },
              ]}
              css={{ marginTop: 35 }}
            />
          </div>
          <div>
            <FooterElement
              heading="À Propos"
              item={[
                { text: 'Financements', url: '/financements/' },
                { text: 'Événements', url: '/evenements/' },
                { text: 'Blog', url: '/blog/' },
                /* { text: 'Podcast', url: '/podcast/' }, */
                { text: 'Questions fréquentes', url: '/faq/' },
                { text: 'Nous contacter', url: '/contact/' },
                { text: 'Recruter nos élèves', url: '/recruteurs/' },
                { text: "Modalités d'accès", url: '/modalites-d-acces/' },
                { text: 'Politique de confidentialité', url: '/politique-de-confidentialite/' },
              ]}
            />
            <FooterElement
              heading="Extra"
              item={[
                { text: 'Apprendre à coder gratuitement', url: '/apprendre-a-coder-gratuitement/' },
                {
                  text: 'Nos élèves créent votre MVP',
                  url: '/produit-minimum-viable-gratuit',
                },
              ]}
              css={{ marginTop: 35 }}
            />
          </div>
          <div /* css={{ flex: 1 }} */>
            <FooterElement
              heading="Suivez-nous"
              item={[
                {
                  text: 'LinkedIn',
                  url: 'https://www.linkedin.com/school/le-reacteur/',
                },
                {
                  text: 'Instagram',
                  url: 'https://www.instagram.com/lereacteur.io/',
                },
                {
                  text: 'Facebook',
                  url: 'https://www.facebook.com/LeReacteurIO/',
                },
                {
                  text: 'YouTube',
                  url: 'https://www.youtube.com/channel/UCZiSHyumY80PJWHUr9jiDvQ',
                },
                { text: 'GitHub', url: 'https://github.com/LeReacteur' },
                // { text: 'Twitter', url: 'https://twitter.com/lereacteurIO' },
                /* { text: 'Medium', url: 'https://medium.com/le-reacteur' }, */
              ]}
            />
          </div>
        </div>
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 80,
          borderTop: '1px solid #2B2166',
          paddingTop: 20,
          flexDirection: 'column',
          [mediaQueries.desktopUp]: {
            flexDirection: 'row',
          },
        }}
      >
        <LogoPartners />
        <Paragraph
          css={{
            textAlign: 'center',
            marginLeft: 'none',
            fontSize: 14,
            flex: 1,
            [mediaQueries.desktopUp]: {
              marginLeft: 'auto',
              textAlign: 'right',
            },
          }}
        >
          {`${date.getFullYear()} © Le Reacteur - Tous droits réservés - Made with React`}
        </Paragraph>
      </div>
    </div>
  );
};
export default FooterLarge;
